<template>
  <v-container class="overflow-y-auto" :style="{ 'height': height + 'px', 'min-height': height + 'px', 'max-height': height + 'px', 'width': '100%' }" fluid grid-list-sm>
    <v-row wrap dense justify-space-around>
      <v-col v-for="project in projects" :key="project.name" cols="12" xs="12" sm="6" md="4" lg="4" xl="3">
        <v-card class="mx-auto d-flex flex-column" height="100%" elevation="12">
          <VideoBackground
            v-if="project.video"
            :src="project.video"
            :playBackRate="0.1"
            :object-fit="project.videoFit"
            :style="{ 'max-height': projectPreviewHeight + 'px', 'height': projectPreviewHeight + 'px', 'width': '100%' }"
          ></VideoBackground>
          <v-img
            v-if="!project.video"
            :src="(project.image ? project.image : require('@/assets/mhps_logo.png'))" :height="projectPreviewHeight + 'px'"
            :cover="project.imageFill !== undefined ? project.imageFill : true"
          ></v-img>
    
          <v-card-title class="sleigher-font">
            <div class="d-flex align-center justify-space-between">
              <div class="d-flex align-center justify-center">
                <v-avatar v-if="project.icon" :image="project.icon" rounded="0" size="30" class="mr-2"></v-avatar>
                <div>{{ project.name }}</div>
              </div>
              <v-btn
              class="pickup-color sleigher-font"
              :disabled="project.actionButtonDisabled ? true : false" variant="text"
              @click="visitURL(project.url)"
            >
              {{ project.actionButtonTitle ?? 'Visit' }}
            </v-btn>
            </div>
          </v-card-title>
    
          <v-card-text class="text-medium-emphasis">
            {{ project.description }}
          </v-card-text>

          <v-card-text>
            {{ project.techDescription }}
          </v-card-text>
          <v-spacer></v-spacer>
          <v-card-actions>
            <div class="d-flex align-center justify-space-between" style="width: 100%;">
              <div class="d-flex align-center justify-center">
                <v-btn v-if="project.projectURL" class="pickup-color sleigher-font" variant="plain" @click="visitURL(project.projectURL)">
                  Project Site
                </v-btn>
                <v-btn v-if="project.privacyPolicyURL" class="pickup-color sleigher-font" variant="plain" @click="visitURL(project.privacyPolicyURL)">
                  Privacy Policy
                </v-btn>
              </div>
              <div class="d-flex align-center justify-center">
                <v-btn v-if="project.facebookURL" class="pickup-color" size="small" variant="plain" icon="mdi-facebook" @click="visitURL(project.facebookURL)">
                </v-btn>
              </div>
            </div>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
  
<script>
  import { ref } from 'vue'
  import VideoBackground from 'vue-responsive-video-background-player'
  export default {
    name: 'Projects',
    props: {
        height: {
            type: Number,
            required: false,
            default: null
        }
    },
    components: {
      VideoBackground
    },
    setup() {

      const projectPreviewHeight = ref(300)

      const projects = ref([
        {
          name: 'iTalkBro',
          url: 'https://apps.apple.com/us/app/italkbro/id573670949',
          privacyPolicyURL: 'https://sites.google.com/view/italkbro-privacy-policy/home',
          facebookURL: 'https://www.facebook.com/iTalkBro',
          icon: require('@/assets/images/iTalkBro_1024.png'),
          video: require('@/assets/captures/iTalkbro_portfolio.mp4'),
          videoFit: 'scale-down',
          actionButtonTitle: 'Get The App',
          description: `A Voice recorder and meme generator for iOS. Based on the 'Talkboy' toy from the classic 90's movie 'Home Alone 2: Lost in New York'.`,
          techDescription: `Implements voice recording and resampling for pitch alteration like the original toy that the 'Kevin McCallister' character uses for his shenanigans. Adds a meme generator function which combines still images, videos, animated GIFs or custom slide animations (using still images) with the recorded audio into a video that can be saved and shared easily.`,
          show: false
        },
        {
          name: 'iScoredBro',
          url: 'https://apps.apple.com/us/app/iscoredbro/id1606955429',
          projectURL: 'https://sites.google.com/view/iscoredbro/home',
          privacyPolicyURL: 'https://sites.google.com/view/iscoredbro-privacy-policy/home',
          video: require('@/assets/captures/iScoredBro_portfolio.mp4'),
          videoFit: 'scale-down',
          icon: require('@/assets/images/iScoredBro_1024.png'),
          actionButtonTitle: 'Get The App',
          description: 'A simple score-keeping application with data logging and export features for iOS and macOS.',
          techDescription: `Implemented as a cross-platform 'Catalyst' app for Apple devices. Provides a 2D graph view of scores versus time for tracking and trending. Export data as .CSV for further analysis.`,
          show: false
        },
        {
          name: 'The Essential Scary Terry',
          url: 'https://www.essentialscaryterry.com/',
          video: require('@/assets/captures/essentialScaryTerry_portfolio.mp4'),
          videoFit: 'scale-down',
          actionButtonTitle: 'Visit',
          description: `An unofficial soundboard for Cartoon Network's 'Rick and Morty' series guest character 'Scary Terry'.`,
          techDescription: 'A simple, responsive, mobile-friendly single-page application implemented with Node.js, Vue.js, and Vuetify.',
          show: false
        },
        {
          name: 'iTrackBro',
          url: 'https://itrackbro.com/',
          video: require('@/assets/captures/iTrackBro_portfolio.mp4'),
          videoFit: 'scale-down',
          actionButtonTitle: 'Visit',
          description: 'A shipped package tracker with rich progress details including a route map visualization. Signing up for a free account enables saving and managing tracked packages.',
          techDescription: `A responsive, dynamic UI for all devices implemented with Node.js, Vue.js. Tightly integrated with Google Maps, Shippo APIs and the Hasura backend-as-a-service platform. Auth0 is integrated as the identity provider platform.`,
          show: false
        },
        {
          name: 'xmas.mjchp.com',
          url: 'https://xmas.mjchp.com/',
          video: require('@/assets/captures/xmas_mjchp_com_portfolio.mp4'),
          videoFit: 'scale-down',
          actionButtonTitle: 'Visit',
          description: `A 'digital greeting card' for friends and family featuring a live-stream of our Christmas tree. (Live content will return during the holiday season of 2024.)`,
          techDescription: `The front-end consists of a lightweight, responsive client website developed in Vue.js leveraging open-source (RTMP-compatible) video player components. Video content is hosted on a Node.js powered backend using an open-source RTMP server library. Live video content is published using a Rasberry Pi 4 + USB Webcam combo.`,
          show: false
        },
        {
          name: 'Trak.Watch',
          url: 'https://trak.watch',
          video: require('@/assets/captures/trakWatch_portfolio.mp4'),
          videoFit: 'scale-down',
          actionButtonTitle: 'Visit',
          description: `A true railfan passion project. Ride along on your favorite scenic Amtrak train (virtually) with an overhead map view and a 3D-rendered 'camera' view showing scenery from the perspective of an engineer in the cab or a passenger looking out of the window (right or left side of the train ;)). Photorealistic satellite imagery and terrain are courtesy of Google Maps. When you're done 'riding' the train, take a peek at up-to-date train status, station weather info and recent Amtrak news articles.`,
          techDescription: `Trak Watch is a personal, years-long project that aims to recreate the sublime experience of riding the California Zephyr through the Rocky Mountains directly in the web browser. The front-end is built in Vue and leans heavily on the Google Maps and CesiumJS libraries to provide rich, photorealistic, 3D (WebGL) map visualizations. 'Near realtime' train movement rendering is powered by up-to-date status information from Amtrak along with historical train location information compiled from the Amtrak network over the past 2 years. For the best experience and frame rates, bring a modern video card that supports accelerated 3D graphics (Nvidia or AMD).`,
          show: false
        },
      ].reverse())
      
      const visitURL = (url) => {
        window.open(url);
      }
      return {
        // UI Config
        projectPreviewHeight,
        // data
        projects,
        // Helper functions
        visitURL
      }
    }
  }
  </script>
  
<style lang="scss" scoped>

.card-actions {
  position: absolute;
  bottom: 1%;
  left: 1%;
} 
</style>