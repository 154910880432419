<template>
    <div class="bg-transparent pa-0 ma-0">
        <v-img
          class="pa-0" 
          :src="require('@/assets/images/garage-tools-wall.jpg')"
          :height="height"
          :style="{ opacity: 0.05 }"
          cover
        ></v-img>
        <v-overlay
            class="d-flex justify-center align-center"
            persistent
            v-model="showResumeOverlay"
            :close-on-back="false"
            :scrim="false"
            contained
        >   
            <Resume :height="height"></Resume>
        </v-overlay>
    </div>
</template>
  
<script>
  import { ref } from 'vue'
  import { useDisplay } from 'vuetify'
  import Resume from './Resume.vue'
  export default {
    name: 'About',
    props: {
        height: {
            type: Number,
            required: false,
            default: null
        }
    },
    components: {
        Resume
    },
    setup() {
        const showResumeOverlay = ref(true)

        // Window Size Mgmt
        const { mdAndUp, smAndUp } = useDisplay()

        return {
        showResumeOverlay,
        // Window Size Management
        smAndUp,
        mdAndUp
      }
    }
  }
  </script>
  
<style lang="scss" scoped>
  
</style>