<template>
    <div class="bg-transparent pa-0 ma-0">
        <v-btn class="no-print" size="medium" icon="mdi-home-circle-outline" @click="goHome"
            :style="{ position: 'fixed', top: '30px', right: '10px', transform: 'translate(-50%, -50%)', 'z-index': 1000 }"
            ></v-btn>
        <v-theme-provider theme="light">
            <Resume id="printable"
                :height="height"
                :width="width"
                :experienceTimeline="false"
                :showBannerImage="false"
                :showContactInfo="true"
                :verticalOverflow="false"
                :uniformFont="true"
                :isDarkTheme="false" />
        </v-theme-provider>
    </div>
</template>
  
<script>
import { ref } from 'vue'
import { useDisplay } from 'vuetify'
import Resume from './Resume.vue'
// import Resume from './Resume.vue'
// import Vue3Html2pdf from 'vue3-html2pdf'
export default {
  name: 'ResumePrinter',
  props: {
      height: {
          type: Number,
          required: false,
          default: null
      },
      width: {
          type: Number,
          required: false,
          default: null
      },
      homeFunction: {
        type: Function,
        required: false,
        default: () => {}
      }
  },
  components: {
  Resume
},
  setup(props) {
      const showResumeOverlay = ref(true)

      // Window Size Mgmt
      const { mdAndUp, smAndUp } = useDisplay()

      // Functions
      const goHome = () => {
        props.homeFunction()
      }

      return {
      showResumeOverlay,
      // Functions
      goHome,
      // Window Size Management
      smAndUp,
      mdAndUp
    }
  }
}
</script>
  
<style lang="scss" scoped>
@media print {
    .no-print {
        visibility: hidden;
    }
}
</style>